import React, { useState} from 'react';
import {db} from '../firebase';
import {addDoc,collection,getDocs} from 'firebase/firestore'
import { createUserWithEmailAndPassword,
signInWithEmailAndPassword,
signOut } from 'firebase/auth';
import {auth} from '../firebase';
import {Link, useNavigate} from 'react-router-dom'
import { UserAuth } from '../auth/AuthContext';
import { isEmpty } from '@firebase/util';


const log_tag = "AdminPage";


const SignUp = () => {
  const [email, setEmail]      = useState('');
  const [password,setPassword] = useState('')
  const [name, setName]        = useState('');
  const [lName, setLName]      = useState('');
  const [role, setRole]        = useState('')
  const [error, setError]      = useState('');

  const {createUser, getUserInfo} = UserAuth();
  const navigate = useNavigate();

  //register accounts and auth
  const handleSubmit_2 = async (event) =>{
    if(isEmpty(name)){
      alert("Enter your name");
    };

      if(isEmpty(lName)){
      alert("Enter your last name");
    };

      if(isEmpty(email)){
      alert("Enter your email");
    };

      if(isEmpty(password)){
      alert("Enter your password");
    };
      if(isEmpty(role)){
        alert("Enter your role");
      };

    event.preventDefault();
    setError('');
    try {
      await createUser(email , password, name, lName, role);
      await getUserInfo(email,role);
      //navigate('/accountpage')

    }catch (event){
        alert(event.message)
        setError(event.message)
    }
  }



  const resetInput =() =>{
    setEmail("")
    setPassword("")
  }

  return (
    <div className='max-w-[700px] mx-auto my-16 p-4 overflow-y-auto'>
          <div className=''>
                    <div className='flex flex-col py-2'>
                          <h1 className="">Sign Up here</h1>
                            <input placeholder='Enter your name' className='border-4 p-3 my-4 rounded-xl' type="text"  onChange={(event) => {setName(event.target.value)}}>
                            </input>

                            <input placeholder='Enter your last name' type="text" className='border-4 p-3 my-4 rounded-xl'  onChange={(event) => {setLName(event.target.value)}}> 
                            </input>

                            <input placeholder='Enter your email' type='email' className='border-4 p-3 my-4 rounded-xl'  onChange={(event) => {setEmail(event.target.value)}}></input>

                            <input placeholder='Enter password' type='password' className='border-4 p-3 my-4 rounded-xl'  onChange={(event) => {setPassword(event.target.value)}}>
                            </input>
                              <input placeholder='Enter your role' type='text' className='border-4 p-3 my-4 rounded-xl'  onChange={(event) => {setRole(event.target.value)}}>
                            </input>

                                <button  className='border border-blue-500 bg-blue-600 hover:bg-blue-500 w-full p-4 my-2 text-white rounded-xl' onClick={handleSubmit_2}>Sign Up</button>
                              <p className='py-2'>
                                   Have an account already? <Link to = '/' className='underline hover:text-gray-500'>Sign in here</Link>
                              </p>  
                    </div>

            </div> 
    </div>
  )
}
export default SignUp;