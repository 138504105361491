import React,{useState, useEffect} from 'react'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { db } from '../firebase';
import {
  collection,
   doc, 
   getDocs, 
   query, 
   querySnapshot,
  where,
deleteDoc}from 'firebase/firestore';
import { UserAuth } from '../auth/AuthContext';
import { async } from '@firebase/util';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { getAuth } from 'firebase/auth';
import { auth } from "../firebase";




const log_tag = "EmployeeData";

const EmployeeData = () => {
  const [params] = useSearchParams();
  const [startDate,setStartDate]=useState(new Date(moment().startOf('isoWeek')));
  const [endDate,setEndDate]=useState(new Date(moment().endOf('isoWeek')));
  const [utcStart, setUtcStart] = useState(Math.floor(moment(startDate.getTime()).valueOf() / 1000));
  const [utcEnd, setUtcEnd] = useState(Math.floor(moment(endDate.getTime()).valueOf() / 1000));
  const navigate = useNavigate()  
  const [data , SetData] = useState([]);
  const [weekHours, setWeekHours] = useState(0);
  const [hourlyRate, setHourlyRate] = useState("")
  const [totalPay, setTotalPay] = useState(0)
  const [useremail, setUserEmail] = useState(params.getAll('email')[0]);
  const {user} = UserAuth;
  console.log(log_tag, "render--" + user);



const fetchData = async (userInfo) =>{

  if ((useremail) && (utcStart > 0) && (utcEnd > 0)) {
    const myCollectionRef = collection(db,'timesheet')
    const myQuery         = query(myCollectionRef, 
                                    where('user_id.email', '==', useremail),
                                    where('utc', '>=', utcStart),
                                    where('utc', '<=', utcEnd));
    console.log(log_tag, "fetchData--" + useremail + " between : " + utcStart + "~" + utcEnd);
    var weekHours1  = Number(0);
    await getDocs(myQuery)
      .then((querySnapshot) => {
       // console.log(log_tag, "fetchData--getDocs = " + querySnapshot.docs.length);
        
        const timeSheet = querySnapshot.docs
                            .map((doc) => ({...doc.data(), 
                              id:doc.id, 
                              dayofweek:dayOfWeek(doc.data().date),
                              dayofweek_num:moment(doc.data().date).day(),
                            }));
        timeSheet.sort((a, b) => (a.utc > b.utc) ? 1 : -1);

        //console.log(log_tag, "fetchData--timeSheet = " + JSON.stringify(timeSheet));
        
        
        timeSheet.forEach(item => {
          //console.log(log_tag, "fetchData--item = " + item.id + "/" + item.total_hours);
          weekHours1 = Number(weekHours1) + Number(item.total_hours);
        })

        setWeekHours(weekHours1);
        
        SetData(timeSheet);
      })
    //console.log(log_tag, "fetchData--weekHours = " + weekHours);
  }
      
};

const dayOfWeek = (dayin) => {
let day = moment(dayin).day();
  switch (day) {
    case 0:
      return "SUNDAY";
    case 1:
      return "MONDAY";
    case 2:
      return "TUESDAY";
    case 3:
      return "WEDNESDAY";
    case 4:
      return "THURSDAY";
    case 5:
      return "FRIDAY";
    case 6:
      return "SATURDAY";
    default:
      return "N/A";
  }

}

useEffect(() => {
  console.log("auth user :" + JSON.stringify(auth));
  getAuth().onAuthStateChanged((user) => {
    if (!user) {
      navigate("/");
    }
  });

  fetchData();

  
}, [utcStart, utcEnd, weekHours, hourlyRate]);

  const onClickReturn = ()=>{
    navigate('/adminpage');
  }

  const handleChange = (e)=>{
    setHourlyRate(e.target.value)
  }

  const CalculatePay = () => {
     let totalPay1 = 0;
      
     if (hourlyRate > 0 || weekHours != null){
      totalPay1 = hourlyRate * weekHours;
      
     }
    if (hourlyRate === 0 ) {
        alert("Please input rate")
      }
    if (weekHours === 0) {
        alert("Weekly Hours is missing")
      }
    else {
      console.error()
    }
      
    setTotalPay(totalPay1);
    setHourlyRate("");
  }

  const deleteDay = async (id) => {
    
    if (auth.currentUser.email !== "admin@njbconstruct.co.nz") {
      alert("You are not authorized to delete timesheet");
      return;
    }
    try {
      //delete from DB
      await deleteDoc(doc(db, "timesheet", id));
      //delete from UI
      const updatedData = data.filter((item) => item.id !== id);
      SetData(updatedData);
      alert("Timesheet entry deleted successfully");
    }
    catch (error) {
      console.log("Error deleting timesheet entry:", error.message);
      alert("Failed to delete timesheet entry, Please try again");
    }
  };

  const buttonShow = (timeSheet) => {
    // console.log(log_tag, "buttonShow--22222" + auth.currentUser.email);
    if (auth.currentUser.email === "admin@njbconstruct.co.nz") {
      return (
        <button>
          <DeleteOutlineIcon
            fontSize="large"
            onClick={() => deleteDay(timeSheet.id)}
            className="cursor-pointer text-red-500 hover:text-red-700"
          />
        </button>
      );
    }
  }


  console.log(log_tag, "render--" + user);
  return (
    <div className="w-full md:w-[1024] h-auto overflow-y-auto">
      <div className=" xs:flex-col md:flex my-20 justify-center">
        <h1 className="p-2 text-xl md:text-2xl py-10">
          Contractor:{" "}
          {params.getAll("name")[0] + " " + params.getAll("lastname")[0]}
        </h1>
        <div className="m-2 flex justify-center">
          <div className=" flex-col md:flex justify-center mr-2 ">
            <DatePicker
              className="my-2 text-center border-2 border-blue-400 rounded-xl "
              rangeHihglight={true}
              showRangeLabels={true}
              selected={startDate}
              onChange={(ev) => {
                setStartDate(ev);
                const utc = moment(ev.getTime()).valueOf() / 1000;
                setUtcStart(Math.floor(utc));
                console.log("UTC Start is " + utc);
              }}
              selectsStart
              //startDate={startDate}
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className="flex flex-col justify-center">
            <DatePicker
              className="my-2 text-center border-2 border-blue-400 rounded-xl"
              rangeHihglight={true}
              showRangeLabels={true}
              selected={endDate}
              onChange={(ev) => {
                setEndDate(ev);
                const utc = moment(ev.getTime()).valueOf() / 1000;
                setUtcEnd(Math.floor(utc));
                console.log("UTC End is " + utc);
              }}
              //selectsStart
              //startDate={startDate}
              //endDate={endDate}
              dateFormat="dd/MM/yyyy"
            />
          </div>
        </div>{" "}
      </div>
      <div
        className=" max-w-screen-xs bg-slate-200 mb-10 md:m-20 rounded-md shadow-xl p-4  md:max-w-auto h-auto"
        style={{ "overflow-y": "scroll" }}
      >
        <div className=" flex  p-4 snap-x">
          {data.map((timeSheet, index) => (
            <div key={index}>
              <div className="p-2 border w-60 rounded-xl text-center text-slate-600 font-bold">
                {timeSheet.dayofweek}
              </div>
              <div className="p-2 border-black w-60 rounded-xl text-center text-slate-600 font-bold">
                Date
              </div>
              <div className="flex-wrap border w-60 rounded-xl text-center ">
                {timeSheet.date}
              </div>
              <div className="p-2 border-black w-60 text-slate-600 font-bold rounded-xl text-center">
                Job Number
              </div>
              <div className="flex-wrap border w-60 rounded-xl text-center  ">
                {timeSheet.job_number}
              </div>
              <div className="p-2 border-black w-60 text-slate-600 font-bold rounded-xl text-center">
                Start Time
              </div>
              <div className="flex-wrap border w-60 rounded-xl text-center">
                {timeSheet.start_time}
              </div>
              <div className="p-2 border-black w-60 text-slate-600 font-bold rounded-xl text-center">
                Finish Time
              </div>
              <div className="flex-wrap border w-60 rounded-xl text-center">
                {timeSheet.finish_time}
              </div>
              <div className="p-2 border w-60 text-slate-600 font-bold rounded-xl text-center">
                Total Hours
              </div>
              <div className="flex-wrap border w-60 rounded-xl text-center">
                {timeSheet.total_hours}
              </div>
              <div className="p-2 border w-60 text-slate-600 font-bold rounded-xl text-center">
                Comment
              </div>
              <div
                className="flex-wrap p-2 h-auto m-2 flex border break-all border-slate-600 w-60 rounded-xl text-clip "
                style={{ whiteSpace: "pre-wrap" }}
              >
                {timeSheet.comment}
              </div>
              { buttonShow(timeSheet) }
         
            </div>
          ))}
        </div>
      </div>
      <div className="md:flex justify-center">
        <div className="m-4 p-2 border w-60 rounded-xl justify-center">
          <h1> Total Weekly Hours : {weekHours} </h1>
        </div>

        <input
          id="hourly_rate"
          type="text"
          className="m-4 p-2 border w-60 rounded-xl justify-center"
          name="hourlyRate"
          value={hourlyRate}
          onChange={(e) => handleChange(e)}
          placeholder="Input hourly rate"
        />

        <button
          className="m-4 p-2 border w-60 rounded-xl  bg-blue-600 
             hover:bg-blue-400 text-center text-white"
          type="button"
          onClick={() => CalculatePay()}
          placeholder="Calculate"
        >
          Calculate
        </button>

        <div className="m-4 p-2 border w-60 rounded-xl justify-center">
          <h1> Total to Pay $ {totalPay} </h1>
        </div>
      </div>
      <div>
        <button
          onClick={onClickReturn}
          className="border px6 py-2 m-4 flex rounded-md w-32 justify-center  bg-slate-200 hover:bg-slate-300 "
        >
          Return
        </button>
      </div>
    </div>
  );
}

export default EmployeeData;