import React from 'react';
import SignUp from './component/SignUp';
import './app.css';
import { Routes, Route } from 'react-router-dom';
import Login from './component/Login';
import AccountPage from './component/AccountPage';
import AdminPage from './component/AdminPage';
import { AuthContextProvider } from './auth/AuthContext';
import ProtectedRoute from './component/ProtectedRoutes';
import "./app.css"
import EmployeeData from './component/EmployeeData';
import ResetPassword from './component/ResetPassword';



function App() {
  return (
    <div className="App">
      <h1 className=" p-10 text-center text-3xl font-bold overflow-y-auto">NJB Construct Admin</h1>
      <AuthContextProvider>
            <Routes>
                
                        <Route path='/' element={<Login/>}/>
                        <Route path='/signup' element={<SignUp/>}/>
                        <Route path='/accountpage' element={<AccountPage/>}/>
                        <Route path='/adminpage' element={<AdminPage/>}/>
                        <Route path='/employeedata' element={<EmployeeData/>}/>
                        <Route path='/resetpassword' element={<ResetPassword/>}/>
                
            </Routes>
      </AuthContextProvider>
    </div>
  );
}

export default App;
