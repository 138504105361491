import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import { UserAuth } from '../auth/AuthContext';



const ResetPassword =  () => {

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const {forgotPassword} = UserAuth(); 
  const [validEmail, setValidEmail] = useState(false);
  
  const handleResetPassword = async (e) => {
    e.preventDefault();
  
    if (!validEmail) {
      alert('Please enter a valid email');
    }
    else {
    try {
      await forgotPassword(email);
      
      alert('Check your inbox for further instructions');
      setEmail('');
    } catch (error) {
      setMessage(error.message);
      alert('This email is not registered with NJB Construct' + email);
    }
    }
  };

  const handleEmailChange = (e) => {
    const emailInput = e?.target.value;
    const emailRegex = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/;

  
    
    if (emailRegex.test(emailInput)) {
      setValidEmail(true);
      
    } else {
      setValidEmail(false);

    }
    setEmail(emailInput);
  };
  
  return (
    <div className=" h-auto md:flex flex-col justify-center align-middle  ">
      <h1 className="m-6  md:m-10 text-center font-sans font-medium text-gray-600">Reset Password</h1>
        <div className='flex justify-center'>
                
                      <form className='flex flex-col justify-center md:flex-none' onSubmit={handleResetPassword}>
                                <input 
                                  placeholder='enter your email'
                                  className='w-[20rem] border-4 p-1 my-4 rounded-xl'
                                  type="email"
                                  id='email'
                                  value={email}
                                  onChange={(e) => handleEmailChange(e)}
                                  required
                                />
                                <button  className='border border-blue-500 bg-blue-600 hover:bg-blue-500 h-10 w-40 ml-2 text-white rounded-xl flex-wrap ' type='submit'>Reset Password</button>
                      </form>
             
      </div>
    </div>
  )
}

export default ResetPassword;