import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from '../auth/AuthContext';
import { collection, getDoc, QuerySnapshot } from 'firebase/firestore';

const log_tag = "Login";

function Login() {
  const [email, setEmail]      = useState('');
  const [password,setPassword] = useState('');
  const [error, setError]      = useState('');
  const {signIn, getUserInfo,user}  = UserAuth();
  const navigate               = useNavigate();



  const isEmpty = (value) => {
    return value === undefined || value === null || value === "";
  }


  const handleSubmit = async (e) =>{

    //const hashedPassword = bcrypt.hashSync(password, 10);
   

    if (isEmpty(email)) {
      alert('Email is required.');
      return;
    }

    if (isEmpty(password)) {
      alert('Password is required.');
      return;
    }
    
    e.preventDefault();
    setError('')
    try {
     
      await signIn(email, password);
      
      await getUserInfo(email);
   
    }
    catch (e){
      setError(e.message)
      alert(e.message)
      navigate('/')
              
    }
  }

  return (
      <div className='max-w-[700px] mx-auto my-16 p-4 overflow-y-auto'>
             <div className='flex flex-col'>              
                   <h1 className='text-2xl font-bold py-2'>Sign in to your account</h1>  
                   <input onChange={(e) => setEmail(e.target.value)} type="email" className='border-4 p-3 my-4 rounded-xl' placeholder='Enter your email'>
                   </input>  
                   <input onChange={(e) => setPassword(e.target.value)} type="password" className='border-4 p-3 my-4 rounded-xl' placeholder='Enter your password'>
                   </input>  
                   <button onClick={handleSubmit} className='border border-blue-500 bg-blue-600 hover:bg-blue-500 w-full p-4 my-2 text-white rounded-xl'>Sign in
                   </button>
                  <div className='md:flex justify-center'>
                          <p className='py-2 pr-2'>
                              Don't have an account yet ? <Link to = '/signup' className='underline hover:text-gray-500'>Sign Up</Link>
                          </p> 
                          <p className='py-2 pl-2'>
                              Forgot your password ? <Link to = '/resetpassword' className='underline hover:text-gray-500 '>Reset Password</Link>
                          </p> 
                  </div>      
             </div>                
      </div> 
  )
}

export default Login