import { createContext, useContext, useEffect, useState} from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail
} from 'firebase/auth'
import {auth} from '../firebase'
import { addDoc,
   collection,
    getDocs,getDoc,
     query,
     where,doc,deleteDoc } from "firebase/firestore";
import { db } from "../firebase";
import { async } from "@firebase/util";
import { useNavigate } from "react-router-dom";
//admin@njbconstruct.co.nz   jonny1234

const UserContext = createContext();
const log_tag = "AuthContextProvider";


export const AuthContextProvider = ({children}) => {

  const [user , setUser] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
  const userCollectionRef = collection(db,"users");
  const [isAdmin, setIsAdmin] = useState(false);

  const navigate = useNavigate();

  let _selectedEmail = "";

  const createUser = (email , password, name, lName, role) => {
    return createUserWithEmailAndPassword(auth, email, password, name,lName, role)
      .then (() => {
        addDoc(userCollectionRef, {name:name, lastName:lName, email:email,  role:role})

        //password:password,
    })
  };

  useEffect(() => {
   
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);

      const userRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists) {
        const userDate = userDoc.data();
        setIsAdmin(userDate.role === 'admin' || userDate.role === 'Admin');
      }
      else {
        setIsAdmin(false);
      }
      setUser(currentUser);

      if (currentUser === "admin" || currentUser === "Admin") {
        setIsAdmin(true);
      }
      else {
        setIsAdmin(false);
      }
    });

    if (selectedEmail) {
        //console.log(log_tag, "useEffect--selectedEmail = " + selectedEmail);
        _selectedEmail = selectedEmail;
       
   
    }
  
    return () => {
      unsubscribe();
    }
    
  }, [user, selectedEmail,isAdmin]);

  console.log(log_tag, "user = " + user);
  console.log(log_tag, "selectedEmail = " + selectedEmail);
  console.log(log_tag,"isAdmin" + isAdmin)

  const setupSelectedEmail = (email, callback) => {
    setSelectedEmail(email);
    if (callback) {
      callback();
    }
  }

  const signIn = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);        
  }
    

    const getUserInfo = async (email) => {

      const myCollectionRef = collection(db, 'users');
      const myQuery = query(myCollectionRef, where('email', '==', email));
      

      const querySnapshot = await getDocs(myQuery);
    
      
      if (querySnapshot.size === 1) {
        setUserInfo(querySnapshot.docs[0].data());

        if (querySnapshot.docs[0].data().role === 'admin' || querySnapshot.docs[0].data().role === 'Admin') {
          navigate('/adminpage', {replace: true});
        } else {
          navigate('/accountpage', {replace: true});
        }

      }

    }



    const logout = async () =>{
      return await signOut(auth)
    }

    const onClickDelete = async (email) =>{
      await deleteDoc(doc(db,'users',email))
      try{
              alert("You successfully deleted account")
        
      }
      catch (e){
          alert(e.message)
      }
  };

  const forgotPassword = (email) => {
    var actionCodeSettings = {
      url: 'https://njbtimesheet.com/',
      handleCodeInApp: true
    }
    return sendPasswordResetEmail(auth, email, actionCodeSettings);
    
  }



  return (
    <UserContext.Provider 
      value={{ createUser, user, logout, signIn, getUserInfo, onClickDelete,userInfo, selectedEmail,setupSelectedEmail, forgotPassword}}>
      {children}
    </UserContext.Provider>
  );
}

export const UserAuth = () => {
  return useContext(UserContext);
}