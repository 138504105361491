
import { initializeApp } from "firebase/app";
import {getFirestore} from '@firebase/firestore';
import {getAuth} from 'firebase/auth';


const firebaseConfig = {
  apiKey: "AIzaSyAMS-rFTrl4DaHBMiMQwgxGKbJnf7fpo5o",
  authDomain: "njb-construct.firebaseapp.com",
  projectId: "njb-construct",
  storageBucket: "njb-construct.appspot.com",
  messagingSenderId: "970260033290",
  appId: "1:970260033290:web:5a64a602dd7e82cb0ad4f3",
  measurementId: "G-K19GDXPJX2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app)