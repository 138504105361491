import React, {useEffect, useRef, useState} from 'react'
import { UserAuth } from '../auth/AuthContext'
import {collection, query, getDocs, where, doc, namedQuery,displayName, addDoc } from 'firebase/firestore';
import { async } from '@firebase/util';
import { getAuth } from 'firebase/auth';
import { auth, db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const log_tag = "AccountPage";

const AccountPage = () => {

 const navigate = useNavigate();
 const {user, logout, userInfo} = UserAuth();
 const [date, setDate] = useState('');
 const [startTime, setStartTime] = useState('');
 const [finishTime, setFinishTime]= useState('');
 const [costHours, setCostHours] = useState([]);
 const [jobNumber, setJobNumber] = useState([])
 const dateInputRef = useRef(null);
 const [comment, setComment] = useState([])
 const [contractorName, setContractorName] = useState(() => {
   return localStorage.getItem('contractorName');
 })
 const [contractorLastName, setContractorLastName] = useState(() => {
  return localStorage.getItem('contractorLastName');
 })

 

const createCol = async (e) =>{
  e.preventDefault(e)
  if (jobNumber === ''){
    alert("Please enter job number")
  return}
  if (date === ''){
    alert("Please select date")
  return}
  if (startTime === ''){
    alert("Please select start time")
  return}
  if (finishTime === ''){
    alert("Please select finish time")
  return}
   if (comment === ''){
    alert("Please enter your comment")
  return}

  

  const tData = {
    job_number: jobNumber,
    date: date,
    start_time: startTime,
    finish_time: finishTime,
    total_hours: costHours,
    user_id: userInfo,
    comment: comment,
    utc : Math.floor(moment(date).valueOf() / 1000)
    
  };
  
  delete tData.user_id.password;

  try{
    //check for duplicate timesheet
  const dataQuery = query(
    collection(db, "timesheet"),
    where("user_id.email", "==", tData.user_id.email),
    where("date", "==", tData.date)
  );

  const querySnapshot = await getDocs(dataQuery);

  if (querySnapshot.size > 0){ 
    alert("You have already submitted your timesheet for this date")
    
  } else{ 
    await addDoc(collection(db,'timesheet'),tData);
    alert("Your form has been submitted");
  
  //clear form
  setCostHours('');
  setDate('');
  setJobNumber('');
  setFinishTime('');
  setStartTime('');
  setComment('');
  }
}
catch (error){
  console.log("Error with timesheet operation:",error.message)
alert ("There was an error with your timesheet operation, Please try again")
};
};



 useEffect(() => {
  getAuth().onAuthStateChanged((user) => {
    if (!user) {
      navigate('/');
    }
  });

  if ((userInfo.name != null) && 
      (userInfo.name != undefined) && 
      (userInfo.name.trim() != "")){
    setContractorName(userInfo.name);
    localStorage.setItem('contractorName',userInfo.name);
  }
  if ((userInfo.lastName != null) && 
      (userInfo.lastName != undefined) && 
      (userInfo.lastName.trim() != "")){
    setContractorLastName(userInfo.lastName);
    localStorage.setItem('contractorLastName',userInfo.lastName);
  }

  timeCalc();
 },[startTime, finishTime]);

  const handleLogOut = async() => {
        try {
          await logout()
          navigate('/')
          alert("You are about to log out")
        }
        catch(e){
          console.log(e.message)
        }
  }

  const handleChange = (e) => {
    setDate(e.target.value); 
  }
  const handleChangeStartTime = (e)=>{
    setStartTime(e.target.value);
    
  }

   const handleChangeFinishTime = (e)=>{
    setFinishTime(e.target.value);
    
  }
  const handleChangeJobNumber = (e)=>{
    setJobNumber(e.target.value);
  }
  const handleComment = (e)=> {
    setComment(e.target.value);
  }
    
  const timeCalc = () => {

    const arrStartH= startTime.split(":");
    const arrFinishH  = finishTime.split(":");

    let finishH = parseInt(arrFinishH[0]) * 60 + parseInt(arrFinishH[1]);
    let startH = parseInt(arrStartH[0]) * 60 + parseInt(arrStartH[1]);
    console.log(startH, "Set start time");
    console.log(finishH);
      var total = (finishH - startH )/60;

    if ((finishH > 0) && (startH > 0)) {
        
    
       if (total > 7){
        total = (finishH - startH - 30) / 60;
      }
      setCostHours(total.toString());
        } 
      else 
        {
          setCostHours("");
        }
        
    return total.toFixed(2);
  }




  return (
    
    <div className='max-w-[1024px] mx-auto my-16 p-4 overflow-y-auto'>
      <p className='text-xl p-2'>User Name: {contractorName + " " + contractorLastName}</p>
      
      <div>
        <h1 className="text-2xl font-bold py-2 text-center">Time Sheet</h1>
       <form onSubmit={createCol} className='flex flex-col'>
          <label className=''>Job reference</label>
          <input className="w-[10rem] border-4 p-1 my-4 rounded-xl " value={jobNumber} 
          onChange={handleChangeJobNumber}></input>
           <label className=''>Set date</label>
          <input type="date" value={date} onChange={handleChange} ref={dateInputRef} className="w-[10rem] border-4 p-1 my-4 rounded-xl "></input>
          <label className=''>Start time</label>
          <input type="time" value={startTime} onChange={handleChangeStartTime} className="w-[10rem] border-4 p-1 my-4 rounded-xl "></input>
          <label className=''>Finish time</label>
          <input type="time" value={finishTime} onChange={handleChangeFinishTime} className="w-[10rem] border-4 p-1 my-4 rounded-xl "></input>
          <label className=''>Total hours</label>
          <output className="w-[10rem] border-4 p-4 my-4 rounded-xl text-center ">{costHours}</output>
           
          <label>Comments</label>
          <textarea value={comment} onChange={handleComment} type="text" className='w-[100%] border-4 p-2 my-4 rounded-xl'></textarea>
            <button onClick={createCol}  className='border border-blue-500 bg-blue-600 hover:bg-blue-500 w-full p-4 my-2 text-white rounded-xl flex-wrap'>Submit
            </button>
    
       </form>
      </div>
      <button onClick={handleLogOut} className='border px6 py-2 my-4 flex rounded-md w-32 justify-center bg-slate-200 hover:bg-slate-300'>Logout</button>
    </div>
  )
}

export default AccountPage