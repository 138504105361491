import React,{useEffect, useState} from 'react'
import { db } from '../firebase';
import {collection, doc, getDocs, querySnapshot, deleteDoc, query, where} from 'firebase/firestore';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {useNavigate, useNavigation} from 'react-router-dom';
import { async } from '@firebase/util';
import { UserAuth } from '../auth/AuthContext';
import { getAuth } from 'firebase/auth';

const log_tag = "AdminPage";

const AdminPage = () => {
  const {user,logout,userInfo, selectedEmail,setupSelectedEmail} = UserAuth();
  const navigate = useNavigate();
  const [showData, setShowData] = useState([]);
  const [adminName, setAdminName] = useState(() => {
    return localStorage.getItem('adminName');
  });
  const [adminLastName, setAdminLastName] = useState(() => {
    return localStorage.getItem('adminLastName');
  });


  useEffect(() =>{
    getAuth().onAuthStateChanged((user) => {
      if (!user) {
      navigate('/')}
      else{
        
        }
      }

  );
    if ((userInfo.name != null) && 
        (userInfo.name != undefined) && 
        (userInfo.name.trim() != "")){
      setAdminName(userInfo.name);
      localStorage.setItem('adminName',userInfo.name);
    }
    
    if ((userInfo.lastName != null) && 
        (userInfo.lastName != undefined) && 
        (userInfo.lastName.trim() != "")){
      setAdminLastName(userInfo.lastName);
      localStorage.setItem('adminLastName',userInfo.lastName);
    }
    fetchPost();
  },[]);

  const fetchPost = async () => {
    const myCollectionRef = collection(db, 'users');
    const myQuery = query(myCollectionRef, where('role', '!=', 'admin'))
    await getDocs(myQuery)
      .then((querySnapshot) => {
        const newData = querySnapshot.docs
        .map((doc) => ({...doc.data(),id:doc.id}));
        setShowData(newData);
        console.log(log_tag, "fetchPost--" + JSON.stringify(newData));
    })
  }

  const handleLogOut = async() => {
    try {
      await logout()
      navigate('/')
    }
    catch(e){
      console.log(e.message)
    }
  }

  const onClickFunc =  (newData) =>{
    setupSelectedEmail(newData.email, ()=> {
      navigate("/employeedata?" + 'name=' + newData.name + '&lastname=' + newData.lastName + '&email=' + newData.email);

    })
  }


  const onClickAdd = () =>{
      navigate("/signup");
  }

  const onClickDelete = async (userObject) =>{
    //console.log(log_tag, ".onClickDelete--" + JSON.stringify(userObject));
    try{
      await deleteDoc(doc(db,
                          'users',
                          userObject.id));
      alert("You successfully deleted account");   
      window.location.reload(true);
    } catch (e) {
      alert("Error:" + e.message);
    }
  };


  return (
    <div className='w-[100%] mx-auto my-16 p-4 text-start overflow-y-auto'>
        <h1 className=' text-xl md:text-2xl py-10'>Administrator :{adminName +" "+ adminLastName}</h1>
              <button className="p-10" onClick={onClickAdd}>{<AddCircleOutlineIcon sx={{fontSize:40}}/>}
              </button> 
                <ul className='md:flex md:pb-20 max-w-full'>
                          <li className='list-none w-full md:w-1/2 md:flex-col space-y-4 h-auto'>
                              {
                                            showData.map((newData,index)=>(
                                              <div className="w-full bg-slate-200 flex rounded-2xl">
                                                <button onClick={() => onClickFunc(newData)} className=' flex w-full rounded-2xl justify-start text-xl p-2 md:p-4 outline-offset-2 bg-gray-200 align-middle hover:bg-slate-300' key={index} newdata={newData}>
                                                  <div className=' pl-2 text-left w-full'>{newData.name}</div>
                                                  <div className=' text-left w-full'>{newData.lastName}</div>
                                                </button>
                                                 <div className="flex justify-center items-center hover:bg-slate-300 rounded-2xl">
                                                   <button className=" " onClick={() => onClickDelete(newData)}>   {<DeleteOutlineIcon sx={{fontSize:40 }}/>}</button>
                                                </div>
                                              
                                                
                                              </div>  
                                            ))
                                          }
                            
                          </li>
                       
                </ul>
            <div className=" flex justify-end md:pr-20 ">
          <button onClick={handleLogOut} className='border px6 py-2 my-4 flex rounded-md w-32 justify-center  bg-slate-200 hover:bg-slate-300 '>Logout</button>
          </div>
    </div>
  )
}

export default AdminPage