import React from 'react'
import { Navigate } from 'react-router-dom';
import { UserAuth } from '../auth/AuthContext';

const ProtectedRoutes = ({children}) => {

const auth  = UserAuth();

  if (!auth.user){
  return  <Navigate to="/login" /> 
  }
  return children;
};

export default ProtectedRoutes;